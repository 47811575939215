import React from "react";
import "./About.css";
import mee from "../../assets/desara3.jpeg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

const About = () => {
  const firstButton = () => {
    const fButton = document.getElementById("first-button");
    const fHr = document.getElementsByClassName("first-hr")[0];
    fButton.style.color = "#2bb673";
    fHr.style.width = "80px";
    fHr.style.color = "#2bb673";

    const sButton = document.getElementById("second-button");
    const sHr = document.getElementsByClassName("second-hr")[0];
    sButton.style.color = "black";
    sHr.style.width = "40px";
    sHr.style.color = "black";

    const thButton = document.getElementById("third-button");
    const thHr = document.getElementsByClassName("third-hr")[0];
    thButton.style.color = "black";
    thHr.style.width = "40px";
    thHr.style.color = "black";

    const educationDiv = document.getElementsByClassName("education");
    const experienceDiv = document.getElementsByClassName("experience");
    const mainSkillDiv = document.getElementsByClassName("main-skills");

    educationDiv[0].style.display = "none";
    mainSkillDiv[0].style.display = "block";
    experienceDiv[0].style.display = "none";
  };
  const secondButton = () => {
    const fButton = document.getElementById("first-button");
    const fHr = document.getElementsByClassName("first-hr")[0];
    fButton.style.color = "black";
    fHr.style.width = "40px";
    fHr.style.color = "black";

    const thButton = document.getElementById("third-button");
    const thHr = document.getElementsByClassName("third-hr")[0];
    thButton.style.color = "black";
    thHr.style.width = "40px";
    thHr.style.color = "black";

    const sButton = document.getElementById("second-button");
    const sHr = document.getElementsByClassName("second-hr")[0];
    sButton.style.color = "#2bb673";
    sHr.style.width = "80px";
    sHr.style.color = "#2bb673";

    const educationDiv = document.getElementsByClassName("education");
    const experienceDiv = document.getElementsByClassName("experience");
    const mainSkillDiv = document.getElementsByClassName("main-skills");

    educationDiv[0].style.display = "none";
    mainSkillDiv[0].style.display = "none";
    experienceDiv[0].style.display = "block";
  };
  const thirdButton = () => {
    const fButton = document.getElementById("first-button");
    const fHr = document.getElementsByClassName("first-hr")[0];
    fButton.style.color = "black";
    fHr.style.width = "40px";
    fHr.style.color = "black";

    const sButton = document.getElementById("second-button");
    const sHr = document.getElementsByClassName("second-hr")[0];
    sButton.style.color = "black";
    sHr.style.width = "40px";
    sHr.style.color = "black";

    const thButton = document.getElementById("third-button");
    const thHr = document.getElementsByClassName("third-hr")[0];
    thButton.style.color = "#2bb673";
    thHr.style.width = "80px";
    thHr.style.color = "#2bb673";

    const educationDiv = document.getElementsByClassName("education");
    const experienceDiv = document.getElementsByClassName("experience");
    const mainSkillDiv = document.getElementsByClassName("main-skills");
    educationDiv[0].style.display = "block";
    experienceDiv[0].style.display = "none";
    mainSkillDiv[0].style.display = "none";
  };
  return (
    <Container id="about-container">
      <Row id="about-section">
        <Col lg={6} >
          <div style={{textAlign: 'center'}}>
            <img src={mee} className="my-image" alt="my"></img>
            </div>
        </Col>
        <Col lg={6} >
          <div>
            <div className="about">
              <h1 className="big">ABOUT ME</h1>
              <h2 className="tr-2">About me</h2>
            </div>
            <p className="about-description">
              I am Desara, a skilled Full Stack Developer with great knowledge
              of some programming languages and data structures. Since 2020 I've been
              working in the field of javascript and its library ReactJs, Python, Django,
              NodeJs etc. I love to learn and have fun while doing it.
              Let's build something special together!
            </p>
          </div>
          <div className="group-button">
            <Row>
              <Col sm={4}>
                <div>
                  <a >
                    <button
                      id="first-button"
                      style={{
                        border: "none",
                        backgroundColor: "white",
                        color: "#4d876a",
                        fontWeight: "600",
                        fontSize: "22px",
                        margin: "0 10px",
                        letterSpacing: "1px"
                      }}
                      onClick={firstButton}
                    >
                      Skills
                    </button>
                  </a>
                  <hr
                    className="first-hr"
                    style={{
                      width: "80px",
                      marginTop: "5px",
                      marginLeft: "15px",
                      color: "#2bb673",
                      opacity: "1",
                      
                    }}
                  />
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <a >
                    <button
                      id="second-button"
                      style={{
                        border: "none",
                        backgroundColor: "white",
                        color: "black",
                        fontWeight: "600",
                        fontSize: "20px",
                        margin: "0 10px",
                        letterSpacing: "1px"
                      }}
                      onClick={secondButton}
                    >
                      Experience
                    </button>
                  </a>{" "}
                  <hr
                    className="second-hr"
                    style={{
                      width: "40px",
                      marginTop: "5px",
                      marginLeft: "15px",
                      color: "black",
                      opacity: "1",
                    }}
                  />
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <a >
                    <button
                      id="third-button"
                      style={{
                        border: "none",
                        backgroundColor: "white",
                        color: "black",
                        fontWeight: "600",
                        fontSize: "20px",
                        margin: "0 10px",
                        letterSpacing: "1px"
                      }}
                      onClick={thirdButton}
                    >
                      Education
                    </button>
                    <hr
                      className="third-hr"
                      style={{
                        width: "40px",
                        marginTop: "5px",
                        marginLeft: "15px",
                        color: "black",
                        opacity: "1",
                        
                      }}
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </div>

          <div className="main-skills">
            <div className="first-div">
              <p className="first-paragraph">
                User experience design
                <span className="workPlace"> - UI/UX</span>
              </p>
              <p className="workPlace">Delight the user and make it work.</p>
            </div>
            <div>
              <p className="first-paragraph">
                Web and user interface design
                <span className="workPlace">- Web Development</span>
              </p>
              <p className="workPlace">Websites, web experiences, ...</p>
            </div>
          </div>
          <div className="experience" style={{ display: "none" }}>
            <div className="first-div">
              <p className="first-paragraph">
                Software developer
                <span className="workPlace"> - NOA</span>
              </p>
              <p className="workPlace">September 2022 - April 2023</p>
            </div>
            <div>
              <p className="first-paragraph">
                Software developer
                <span className="workPlace">
                  - Idra Research and Consulting
                </span>
              </p>
              <p className="workPlace">July 2021 - September 2022</p>
            </div>
          </div>
          <div className="education" style={{ display: "none" }}>
            <div className="first-div">
              <p className="first-paragraph">
                Web Development, Python
                <span className="workPlace"> - Dominusoft Trainings</span>
              </p>
              <p className="workPlace">December 2021 - March 2022</p>
            </div>
            <div>
              <p className="first-paragraph">
                Diploma in Computer Science
                <span className="workPlace">
                  - Polytechnic University of Tirana
                </span>
              </p>
              <p className="workPlace">October 2018 - July 2021</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default About;
