import React from 'react'
import "./Projects.css"
import { Container, Row, Col } from 'react-bootstrap'
import todoapp from "../../assets/todoapp.PNG"
import weather from "../../assets/weather.PNG"
import Login1 from "../../assets/Login2.PNG"
import Register from "../../assets/Register.PNG"
import Nasa from "../../assets/nasa3.PNG"
import tictactoe from "../../assets/tictactoe.PNG"
import instagram from "../../assets/instagram.PNG"
import game from "../../assets/game.PNG"
import autocad from "../../assets/autocad.PNG"

const Projects = () => {
    return (
        
        <Container>
            <Row>
                <Col xs={12} sm={12} md={4}>

                    <div className='portfolio-box'>
                        <div className='portfolio-img'>
                            <div className='portfolio-info'>
                                <h5 className='projects-title'>To do app</h5>
                                <p className='programming-language'>HTML,CSS,ReactJs</p>
                            </div>
                            <a className='projects-image'>
                                <img  src={todoapp} alt=""></img>
                            </a>
                        </div>
                    </div>

              </Col>
              <Col xs={12} sm={12} md={4}><div className='portfolio-box'>
                  <div className='portfolio-img'>
                      <div className='portfolio-info'>
                          <h5 className='projects-title'>Weather app</h5>
                          <p className='programming-language'>HTML,CSS,ReactJs</p>
                      </div>
                      <a className='projects-image'>
                          <img src={weather}></img>
                      </a>
                        
                  </div>
              </div>
              </Col>
              <Col xs={12} sm={12} md={4}><div className='portfolio-box'>
                  <div className='portfolio-img'>
                      <div className='portfolio-info'>
                          <h5 className='projects-title'>Login Form</h5>
                          <p className='programming-language'>HTML,CSS,Bootstrap,Python,Django</p>
                      </div>
                      <a className='projects-image'>
                          <img src={Login1}></img>
                      </a>
                       
                  </div>
              </div>
              </Col>
          </Row>
          <Row>
              <Col xs={12} sm={12} md={4}><div className='portfolio-box'>
                  <div className='portfolio-img'>
                      <div className='portfolio-info'>
                          <h5 className='projects-title'>Register Form</h5>
                          <p className='programming-language'>HTML,CSS,Bootstrap,Python,Django</p>
                      </div>
                      <a className='projects-image'>
                          <img src={Register}></img>
                      </a>
                        
                  </div>
              </div>
              </Col>
              <Col xs={12} sm={12} md={4}><div className='portfolio-box'>
                  <div className='portfolio-img'>
                      <div className='portfolio-info'>
                          <h5 className='projects-title'>NASA Photo of the Day</h5>
                          <p className='programming-language'>HTML,CSS,ReactJs</p>
                      </div>
                      <a className='projects-image'>
                          <img src={Nasa}></img>
                      </a>
                        
                  </div>
              </div>
              </Col>
              <Col xs={12} sm={12} md={4}><div className='portfolio-box'>
                  <div className='portfolio-img'>
                      <div className='portfolio-info'>
                          <h5 className='projects-title'>Tic Tac Toe</h5>
                          <p className='programming-language'>HTML,CSS,JS</p>
                      </div>
                      <a className='projects-image'>
                          <img src={tictactoe}></img>
                      </a>
                       
                  </div>
              </div>
              </Col>
          </Row>
          <Row>
              <Col xs={12} sm={12} md={4}><div className='portfolio-box'>
                  <div className='portfolio-img'>
                      <div className='portfolio-info'>
                          <h5 className='projects-title'>Instagram Clone</h5>
                          <p className='programming-language'>HTML,CSS,Bootstrap,ReactJs</p>
                      </div>
                      <a className='projects-image'>
                          <img src={instagram}></img>
                      </a>
                       
                  </div>
              </div>
              </Col>
              <Col xs={12} sm={12} md={4}><div className='portfolio-box'>
                  <div className='portfolio-img'>
                      <div className='portfolio-info'>
                          <h5 className='projects-title'>Javascript game</h5>
                          <p className='programming-language'>HTML,CSS,JS</p>
                      </div>
                      <a className='projects-image'>
                          <img src={game}></img>
                      </a>
                        
                  </div>
              </div>
              </Col>

              <Col xs={12} sm={12} md={4}><div className='portfolio-box'>
                  <div className='portfolio-img'>
                      <div className='portfolio-info'>
                          <h5 className='projects-title'>Autocad Project</h5>
                          <p className='programming-language'>AutoCad</p>
                      </div>
                      <a className='projects-image'>
                          <img src={autocad}></img>
                      </a>
                        
                  </div>
              </div>
              </Col>
          </Row>
         </Container>
    )
}

export default Projects