import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Portfolio.css";
import heart from "../../assets/heart1.PNG"
import fitnessblog from "../../assets/fitnessblog.PNG"
import idrapoll2 from "../../assets/idrapoll2.PNG";
import { FiChevronRight } from "react-icons/fi"
import { Link } from "react-router-dom";


const Portfolio = () => {
  return (
    <Container  id="portfolio" fluid>
      <div className="portfolio-all">
        <h1 className="portfolio-title">MY PORTFOLIO</h1>
        <h2 className="portfolio-small">Recent Projects</h2>
        <p className="portfolio-des">
          Have a look at my latest works
        </p>
      </div>
      <div className="view-button">
        <div className="view-icon"><FiChevronRight /></div>
        <Link to="/projects" style={{ textDecoration: "none" }}>
          <p className="view-paragraph">View all</p>
        </Link>
      </div>

      <Container>
        <Row>
          <Col className="portfolio-col" lg={4}>
            <div class="effect">
              <div class="effect-img">
              <img src={idrapoll2}></img>
              </div>
              <div class="effect-text">
                <div class="inner">
                  <h2 className="portfolio-titlee"><span className="idra">IDRA</span><span className="poll">poll</span></h2>
                  <p className="portofolio-description">IDRApoll is the first community of professional online surveys in Albania and Kosovo. As an IDRApoll panelist, you will be rewarded for your opinion</p>
                  
                </div>
              </div>
            </div>

          </Col>
          <Col className="portfolio-col" lg={4}>
            <div class="effect">
              <div class="effect-img">
              <img src={fitnessblog}></img>
              </div>
              <div class="effect-text">
                <div class="inner">
                  <h2 className="portfolio-titlee"><span className="stay">Stay</span><span className="fit">FIT</span></h2>
                  <p className="portofolio-description">StayFit is a blog which inspires women everywhere to find their own healthy, happy bodies. It's much more than healthy meals and killer workouts</p>
                  
                </div>
              </div>
            </div>

          </Col>
          <Col className="portfolio-col" lg={4}>
            <div class="effect">
              <div class="effect-img">
              <img src={heart}></img>
              </div>
              <div class="effect-text">
                <div class="inner">
                  <h2 className="portfolio-titlee">Disease Prediction</h2>
                  <p className="portofolio-description">This application predicts the chances of Heart Disease and classifies patient's risk level by implementing different data mining techniques such as Naive Bayes, Decision Tree, Logistic Regression and Random Forest</p>
                 
           
                </div>
              </div>
            </div>
          </Col>
        </Row>

      </Container>
    </Container>
  );
};
export default Portfolio;
