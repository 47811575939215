import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./components/About/About";
import Footer from "./components/Footer/Footer";
import Howitwork from "./components/Howitwork/Howitwork";
import Landing from "./components/Landing/Landing";
import Portfolio from "./components/Portfolio/Portfolio";
import Services from "./components/Services/Services";
import Hire from "./components/Hire/Hire";
import Testimonials from "./components/Testimonials/Testimonials";
import Blog from "./components/Blog/Blog";
import Projects from "./components/Projects/Projects";




class App extends React.Component {

  render() {
    return (
      <div className="App" data-scroll-section>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Landing />} />
          </Routes>
          <Routes>
            <Route path="/" element={<About />} />
          </Routes>
          <Routes>
            <Route path="/" element={<Services />} />
          </Routes>

          <Routes>
            <Route path="/" element={<Howitwork />} />
          </Routes>

          <Routes>
            <Route path="/" element={<Portfolio />} />
          </Routes>

          <Routes>
            <Route path="/" element={<Testimonials />} />
          </Routes>

          {/* <Routes>
        <Route path="/" element={<Blog/>}/>
      </Routes> */}

          <Routes>
            <Route path="/" element={<Hire />} />
          </Routes>

          <Routes>
            <Route path="/" element={<Footer />} />
          </Routes>

          <Routes>
            <Route path="/projects" element={<Projects />} />
          </Routes>

        </BrowserRouter>
      </div>
    );
  }
}
export default App;
