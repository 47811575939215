import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css"
import { FaInstagram } from "react-icons/fa"
import { FaGithub } from "react-icons/fa"
import { FaLinkedin } from "react-icons/fa"
import {AiOutlineMail} from "react-icons/ai"
import {MdContactPhone} from "react-icons/md"
import {Link} from "react-router-dom"
const Footer = () => {
    return (
        <Container fluid id="footer">
            <Container className="inner-container">
                <Row>
                    <Col>
                    </Col>
                    <Col sm={12} md={2} lg={2}>
                        <a href="#about-container"><p className="footer-elements">About me</p></a>
                    </Col>
                    <Col sm={12} md={2} lg={2}>
                    <a href="#services"><p className="footer-elements">Services</p></a>
                    </Col>
                    <Col sm={12} md={2} lg={2}>
                    <Link to="/projects" style={{ textDecoration: "none" }}>
                    <a href=""><p className="footer-elements">Projects</p></a>
                    </Link>
                    </Col>
                    <Col sm={12} md={2} lg={2}>
                    <a href="#hire-section"><p className="footer-elements">Contact</p></a>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row className="social-media-icons">
                    <Col>
                    </Col>
                    <Col className="footer-icons">
                    <a href=""><span className="instagram"><FaInstagram /></span></a>
                    </Col>
                    <Col className="footer-icons">
                    <a href="https://github.com/Desara99"><span className="github" ><FaGithub /></span></a>
                    </Col>
                    <Col className="footer-icons">
                    <a href="https://www.linkedin.com/in/desara-shinjatari-4424001b7/"><span className="linkedin" ><FaLinkedin /></span></a>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <p className="copyright">All copyrights reserved 2023-Designed and Developed by The full Stack Desara</p>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
export default Footer;