import React from "react";
import { MdLaptopWindows } from "react-icons/md";
import { IoPeopleOutline } from "react-icons/io5";
import { AiOutlineGift } from "react-icons/ai";
import "./Howitwork.css";

import { Col, Container, Row } from "react-bootstrap";


const Howitwork = () => {

  return (
    <section id="how-it-work">
      <div >
        <img className="how-it-work-bg"></img>
        <h1 className="how">What I offer</h1>
        <p className="how-p" >Contact me to discuss your needs and how I may assist you.</p>

        <div className="card-div">
          <Container>
          <Row>
            <Col md={4}>
              <div className="cards-how-it-work ">
                <div>
                  <MdLaptopWindows className="how-it-work-icon"></MdLaptopWindows>
                </div>
                <h2 className="how-it-work-title">Beautiful Designs</h2>
                <p className="how-it-work-description-active">
                 I will build interfaces selecting the most beautiful and professional color combinations and other graphics to make websites more attention-grabbing.
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="cards-how-it-work active">
                <div>
                  <AiOutlineGift className="how-it-work-icon ic"></AiOutlineGift>
                </div>
                <h2 className="how-it-work-title">Unique features</h2>
                <p className="how-it-work-description">
                 Websites don't have to be static. I love making pages come to life using relevant and newest animations, custom videos and other useful features.
                </p>
              </div>
            </Col>
            <Col md={4}>
              <div className="cards-how-it-work active">
                <div>
                  <IoPeopleOutline className="how-it-work-icon ic"></IoPeopleOutline>
                </div>
                <h2 className="how-it-work-title">Reliable support</h2>
                <p className="how-it-work-description">
                  I will provide relevant and meaningful information to the users guiding them to their final goal and will answer their questions 24 hours a day.
                </p>
              </div>
            </Col>
          </Row>
          </Container>
        </div>
      </div>
    </section>
  );
};
export default Howitwork;
