import React from 'react'
import { useState } from 'react';
import "./Hire.css";
import axios from "axios"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import hire from "../../assets/hire.jpg";
import emailjs from "@emailjs/browser"
const Hire = () => {

  const [mailerState, setMailerState] = useState({
    name: "",
    email: "",
    subject:"",
    message: "",
  });

  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  const submitEmail = (e) => {
    e.preventDefault();
    console.log({ mailerState });
    
    emailjs.sendForm('service_hof7emo','template_d5q6pc9',e.target,'ZmYGGwsO3XtD3pXT4');
     
        setMailerState({
          email: "",
          name: "",
          message: "",
        });
      
  };

  return (
    <Container id="hire-container">
     
      <Row id="hire-section">
        
        <Col lg={6}>
          
          <div className="">
            <h1 className='hire-big'>Hire me</h1>
            <p className='hire-description'>I am available for freelance work. Connect with me via phone: <span className='hire-tel'>+365 682363 360</span> or email: <span className='hire-email'>fullstackdesara@gmail.com</span></p>
            
          </div>
          <div>
            <form className='form-wrapper' onSubmit={submitEmail}>
                <input type="text" name="name" placeholder='Your Name *' onChange={handleStateChange} value={mailerState.name}></input>
                <input type="text" name="email" placeholder='Your Email *' onChange={handleStateChange} value={mailerState.email}></input>
                <input type="text" name="subject" placeholder='Write a subject *' onChange={handleStateChange} value={mailerState.subject}></input>
                <textarea type="text" name="message" placeholder='Write a message' onChange={handleStateChange} value={mailerState.message}></textarea>
                <button className='hire-button' type='submit'>SEND</button>
            </form>
          </div>
        </Col>
        <Col lg={6}>
          <div>
            <img src={hire} className="hire-image"></img>
          </div>
        </Col>
        
      </Row>
    </Container>
  )
}

export default Hire;