import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Services.css";
import { MdOutlineDesignServices, MdArchitecture } from "react-icons/md";
import { RiComputerLine } from "react-icons/ri";
const Services = () => {
  return (
    <Container fluid id="services">
      <div className="services-all">
        <h1 className="services-big">SERVICES</h1>
        <h2 className="services-small">What I do</h2>
      </div>
      <Container className="services-icon">
        <Row>
          <Col className="col-services" md={4}>
            <MdOutlineDesignServices className="design" />
            <p className="design-des">Web Design & Development</p>
            <p className="services-description">
            Designing (or redesigning) and developing websites with high quality and the latest technology, so that it is as easy to use as possible, intuitive and impactful
            </p>
          </Col>
          <Col className="col-services" md={4}>
            <RiComputerLine className="design" />
            <p className="design-des">Responsive</p>
            <p className="services-description">
            The design of your website is such that it runs easily on all devices, including mobile phones, tablets, computers, etc and delivering the results your business and end-users need
            </p>
          </Col>
          <Col className="col-services" md={4}>
            <MdArchitecture className="design" />
            <p className="design-des">AutoCad Services</p>
            <p className="services-description">
             Creating full-scale drawings of architectural plans and detailed designs of buildings, structures and interiors using CAD software according to specifications from architects  
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
export default Services;
