import React from "react";
import "./Landing.css";
import { Col } from "reactstrap";
import Typewriter from "typewriter-effect";
import { animateScroll } from 'react-scroll';

const Landing = () => {

  const scrollToBottom = () => {
    animateScroll.scrollToBottom({
      duration: 500,
      scroll: 'easeInOutQuad',
    });
  };

  return (
    <section id="landing">
      <div className="bg">
        <Col className="heroTextSection">
          <Typewriter
            options={{ delay: 60, autoStart: true, deleteSpeed: 4 }}
            onInit={(typewriter) => {
              typewriter
                .typeString(
                  '<span class ="fjalia-1">Hi, I am Full Stack Desara <br/>'
                )
                .typeString(
                  '<span class ="fjalia-2">I love to create amazing apps and designs </span>'
                )
                .start();
            }}
          />
          <div className="button-section">
            <a href="#portfolio">
              <button className="read-button">
                <p>EXPLORE WORKS</p>
              </button>
            </a>
          </div>
        </Col>
      </div>
    </section>
  );
};
export default Landing;
