import React from "react";
import "./Testimonials.css";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
const Testimonials = () => {
  const settings = {

    arrows:true,
    dots:false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 5500,
    pauseOnHover: false,


  };
  return (
    <div id="testimonials">
      <h1 className="testimonials-title">TESTIMONIALS</h1>
       <Slider {...settings} className="slider">
       
         <div className="testimonials-div">
          <p className="testimonials-txt">Desara is very reliable about being at work on time and performs at expectations. She is very motivated to finish tasks and assignments on time. Shows ability to come up with new solutions to common problems.</p>
          <p className="testimonials-name">Sergi</p>
          <p className="testimonials-role">Web Developer</p>
        </div>
        <div className="testimonials-div">
          <p className="testimonials-txt">We've had the pleasure of working with Desara and during that time they have consistently impressed me with their dedication, work ethic, and attention to detail.They consistently exceed expectations and are always willing to go above and beyond to ensure that projects are completed on time </p>
          <p className="testimonials-name">Kledja</p>
          <p className="testimonials-role">Business Owner</p>
        </div>
        
        {/* <div className="testimonials-div">
          <p className="testimonials-txt"></p>
          <p className="testimonials-name">Eri</p>
        <p className="testimonials-role"></p>
        </div>  */}
        
       </Slider>
      
    </div>
  );
};

export default Testimonials;
